import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Slant as Hamburger } from "hamburger-react";

export const Navbar = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [hamburger, setHamburger] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (windowSize.innerWidth < 768 && !hamburger) {
    setHamburger(true);
  } else if (windowSize.innerWidth >= 768 && hamburger) {
    setHamburger(false);
    setOpen(false);
  }

  return (
    <header className="px-5 sm:mb-6 sm:pt-4 flex flew-row justify-between max-w-screen-lg mx-auto">
      <div className="bg-gradient-to-r from-transparent via-blue-600 to-transparent absolute p-0.5 px-24 top-12 sm:top-16"></div>
      <div className="w-full flex pt-5 pb-10 items-baseline justify-between">
        <Link
          className="text-2xl title-font font-medium text-black mb-3 mr-2"
          to="/about"
        >
          Frederic Robinson
        </Link>

        {/* {!hamburger && <Menu />} */}

        {/* {hamburger && <HamburgerMenu isOpen={isOpen} setOpen={setOpen} />} */}
      </div>
      {/* {hamburger && (
        <div className="pt-3">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
      )} */}
    </header>
  );
};

const Menu = (props) => {
  const [activePage, setActivePage] = useState("");

  const [colorAbout, setColorAbout] = useState("");
  const [colorWork, setColorWork] = useState("");
  const [colorResearch, setColorResearch] = useState("");

  const handleClick = (page) => {
    setColorAbout("");
    setColorWork("");
    setColorResearch("");

    switch (page) {
      case "about":
        setActivePage("about");
        setColorAbout("text-blue-600");
        break;
      case "projects":
        setActivePage("projects");
        setColorWork("text-blue-600");
        break;
      case "research":
        setActivePage("research");
        setColorResearch("text-blue-600");
        break;
    }
  };

  const showActivePage = (page) => {
    handleClick(page);
  };

  useEffect(() => {
    const page = window.location.pathname.split("/")[1];
    setActivePage(page);
    showActivePage(page);
  }, []);

  return (
    <nav
      className={
        "w-72 text-gray-900 flex flex-row justify-between hover:text-gray-400 font-medium"
      }
      onMouseEnter={() => handleClick("nada")}
      onMouseLeave={() => showActivePage(activePage)}
    >
      <Link
        className={
          "hover:text-blue-600 transition-all duration-200 " + colorAbout
        }
        to="/about"
        onClick={() => handleClick("about")}
      >
        ABOUT
      </Link>
      <Link
        className={
          "hover:text-blue-600 transition-all duration-200 " + colorWork
        }
        to="/projects"
        onClick={() => handleClick("projects")}
      >
        WORK
      </Link>
      <Link
        className={
          "hover:text-blue-600 transition-all duration-200 " + colorResearch
        }
        to="/research"
        onClick={() => handleClick("research")}
      >
        RESEARCH
      </Link>
    </nav>
  );
};

const HamburgerMenu = (props) => {
  const animation = " opacity-100 mt-11 ";
  const handleClick = () => {
    props.setOpen(false);
  };

  return (
    <nav
      className={
        "absolute mt-6 w-full justify-evenly text-gray-900  flex flex-row opacity-0 ease-in-out duration-500 hover:text-gray-400 font-medium transition-all " +
        (props.isOpen && animation)
      }
      onClick={handleClick}
    >
      <Link
        className="hover:text-gray-900 transition-all duration-200"
        to="/about"
      >
        ABOUT
      </Link>
      <Link
        className="hover:text-gray-900 transition-all duration-200"
        to="/projects"
      >
        WORK
      </Link>
      <Link
        className="hover:text-gray-900 transition-all duration-200"
        to="/research"
      >
        RESEARCH
      </Link>
    </nav>
  );
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

// sm	640px
// md	768px
// lg	1024px
// xl	1280px

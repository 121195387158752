export const projects = {
  "schindler-city-center-lucerne": {
    order: 1,
    projectName: "Schindler City Center Lucerne",
    tagline: "An Immersive Brand Space on Four Floors",
    skills: "Creative Direction • Project Management",
    year: "2020",
    projectLink: "/projects/schindler-city-center-lucerne",
    projectTags: "All Projects Installation Selected Projects UX",
    teaserImage: "/projects/schindler/schindler-teaser.gif",
    pictureGallery: {
      path: "/projects/schindler/schindler-gallery",
      pictures: 13,
    },
    videoID: "sNn1QLtSJho",
    shortDescription:
      "Over a period of three years, we realized the Holistic Sound Scenography for the new Visitor Center at Schindler's headquarters in Lucerne. The entire exhibition extends over four floors and a total space of 2000 square meters. Within this space there are eight unique installations equipped with 293 loudspeakers. Each sound installation has its own distnct character, while embedded in a holistic sound scenography concept we developed for the space as a whole.",
    longDescription:
      "A huge variety of musical elements and sound effects find their place in the overall composition that reflects the company`s philosophy, technological orientation and high-quality standards. In the entire process starting with technical loudspeaker planning and multi-channel compositions up to the implementation of interactive sound elements, every step was developed in close cooperation with iart and Schindler. \n\nThe building on the Schindler Campus in Ebikon was designed by the architects Burckhardt+Partner. Through the glazed exterior facade, guests can already see the moving lifts and escalators from the outside. Nonetheless, they not only move visitors, but are also a central element of the scenography: For instance, when standing on an escalator, one can see the Schindler Campus and the surrounding landscape cinematically passing by. This is precisely the experience one makes in the Schindler Visitor Center with a wide variety of exhibits: You move yourself and the installations move around you. This creates a human-technology performance: a model case for the mobility of the future.",
    role: "Creative Direction (Audio) 2017 - 2019 \nProject Management (Audio) 2017 - 2019 \n\nfor Idee und Klang Audio Design",
    credits:
      "Client: Schindler Aufzüge AG \nMedia technology: iart \nInterior design and scenography: Frank Dittmann GmbH in collaboration with iart (Level 4: ZMIK) \nMedia production: TAMSCHICK MEDIA+SPACE \nArchitecture: Burckhardt+Partner \nSpatial graphics: AAA Studio and Maria Rosa Jehle \nDesign: Bhend Design Studio, ZMIK \nVisuals: case \nConstruction management: kpm3 \nImages: © iart 2020",
  },

  debris: {
    order: 2,
    projectName: "Debris",
    tagline: "An Interface for Direct Manipulation of Audio Waveforms",
    skills: "Prototyping",
    year: "2021",
    projectLink: "/projects/debris",
    projectTags: "All Projects Interactive Selected Projects UX",
    teaserImage: "/projects/debris/debris-teaser.gif",
    pictureGallery: {
      path: "/projects/debris/debris-gallery",
      pictures: 4,
    },
    videoID: "QLmTN8rUn0A",
    videoID2: "7yITS11QII4",
    shortDescription:
      "Debris is a playful interface for direct manipulation of audio waveforms. Audio data is represented as a collection of waveform elements, which provide a low-resolution visualisation of the audio sample. Each element, however, can be individually examined, re-positioned, or broken down into smaller fragments, thereby becoming a tangible representation of a moment in the sample. Debris is built around the idea of looking at a sound not as a linear event to be played from beginning to end, but as a non-linear collection of moments, timbres, and sound fragments which can be explored, closely examined and interacted with.",
    longDescription:
      "Debris was prototyped using the Unity game engine, which had two key benefits: 1) it allowed for a quick and relatively easy implementation of physical properties into objects, and 2) it provided straight forward cross-platform support for desktop (Mac and PC), and mobile devices (iOS, Android). Unity's physics engine and 3D rendering also allows for easy implementation of experimental features such as drastically changing camera perspectives or linking gravity to mobile device orientation, features which were explored during the prototyping phase but not implemented in the final version. To maximise speed and flexibility during the prototyping phase, the user interface was developed in Unity, and control parameters were then sent out via OSC to an external audio engine built in MaxMSP. In a later phase, the audio engine was ported to C# to natively run within Unity. Debris was also built as a Max 4 Live device to integrate into music production workflows in Ableton Live. This version has no physics engine, and instead features extended tempo sync features and tone controls.",
    role: "Sonic Interaction Design \nDigital Musical Instrument (DMI) Design",
    tech: "Unity, C# \nJavaScript \nMax MSP, Max for Live",
    recognition:
      "• Publication: Robinson - 'Debris: A playful interface for direct manipulation of audio waveforms'",
  },

  "national-museum-of-qatar": {
    order: 3,
    projectName: "National Museum Of Qatar",
    tagline: "3D Sound for the World's Largest Video Installation",
    skills: "Immersive Sound Design",
    year: "2019",
    projectLink: "/projects/national-museum-of-qatar",
    projectTags: "All Projects Installation Selected Projects UX",
    teaserImage: "/projects/nmoq/nmoq-teaser.gif",
    pictureGallery: { path: "/projects/nmoq/nmoq-gallery", pictures: 14 },
    videoID: "XC9PBxq3ZO4",
    videoID2: "U527V_1gP5U",
    shortDescription:
      "Star architect Jean Nouvel was commissioned to build the new National Museum of Qatar, which opened its doors at the end of March 2019. Inspired by the local desert rose, he designed a gigantic building complex made of 539 curved disks. On an exhibition surface area of 8000 square metres, the museum is home to a total of twelve topical galleries showing ultra-large-scale film projections that take the visitors on an immersive journey through Qatar's history. It is the world's largest permanent video installation.",
    longDescription:
      "Engaged as audio consultants by the National Museum, Idee und Klang Audio Design created the complete sound scenography for the museum - from the early planning stage to the final mix of the audio content on-site. Specifications and positions of a total of 331 speakers were determined based on a complex and detailed acoustic model of the entire exhibition area. A style guide was developed to enable the soundtracks of each gallery's art films to co-exist within the open architecture. All films were pre-mixed in Idee und Klang's multi-channel studio and eventually mixed on the large-scale speaker setups on-site. \n\nThe holistic approach to exhibition sound (from planning, over styleguide & pre-mix, to on-site mix) allowed the very different film soundtracks to work next to each other to create a seamless audio experience despite the unique acoustic conditions due to the complex architecture.",
    role: "Re-recording Mix \nOn-Site Mix \nOn-Site Project Management \n\nfor Idee und Klang Audio Design",
    credits:
      "Client: MEJV (MAN / EMPTY Joint Venture) \nArchitecture: Ateliers Jean Nouvel \nTechnical planning: Dean Winkler WCI \nProjection mapping: RES \nFilm production: Doha Film Institute \nDirectors: Christophe Cheysson, Jananne Al-Ani, Abderrahmane Sissako, Mira Nair, Peter Webber, Doug Aitken, John Sanborn \nPhotography: RES & Idee und Klang Audio Design",
  },

  dolby: {
    order: 4,
    projectName: "Dolby Laboratories",
    tagline: "Product Ideation for Distributed Smart Devices",
    skills: "UX Research • Prototyping",
    year: "",
    projectLink: "/projects/museum-of-the-future-2019",
    projectTags: "All Projects Installation UX locked",
    teaserImage: "/projects/dolby/dolby-teaser.png",
    pictureGallery: { path: "/projects/mof19/mof19-gallery", pictures: 3 },
    videoID: "",
    shortDescription: "",
    longDescription: "",
    role: "",
    credits: "",
  },

  meta: {
    order: 5,
    projectName: "Meta Inc.",
    tagline: "Exploring Audio Interactivity for AR/VR",
    skills: "Product Ideation • Prototyping",
    year: "",
    projectLink: "/projects/museum-of-the-future-2019",
    projectTags: "All Projects Installation UX locked",
    teaserImage: "/projects/meta/meta-teaser.jpg",
    pictureGallery: { path: "/projects/mof19/mof19-gallery", pictures: 3 },
    videoID: "",
    shortDescription: "",
    longDescription: "",
    role: "",
    credits: "",
  },

  "museum-of-the-future-2019": {
    order: 6,
    projectName: "World Government Summit Dubai 2019",
    tagline: "Immersive Soundscapes for the Museum of the Future",
    skills: "Creative Direction • Project Management",
    year: "2019",
    projectLink: "/projects/museum-of-the-future-2019",
    projectTags: "All Projects Installation UX",
    teaserImage: "/projects/mof19/mof19-teaser.jpeg",
    pictureGallery: { path: "/projects/mof19/mof19-gallery", pictures: 3 },
    videoID: "9lteS5fpmdQ",
    shortDescription:
      "The Museum of the Future 2019, titled “Humans 2.0” and taking place during the World Government Summit in Dubai, explored the ideas and concepts of human augmentation. The exhibition invites the audience to imagine a future where human’s transcend the limitations of their bodies and minds.",
    longDescription:
      "Idee und Klang commissioned composers Alex Melia and Jan Sutter and worked with sound designer Lorenz Fischer to create music and sound design for all eight areas of the exhibition, taking the visitors on an immersive musical journey from the year 2040 to 2100. \n\nThis approach allowed visitors to explore the different sections with their own individual sonic themes and musical language and at the same time move through one coherent overall soundscape representing the core theme of the exhibition. \n\nOne particular challenge was that the areas were not acoustically separated, meaning other parts of the museum were always in hearing distance. As a result, the team created distinct compositions for each area which simultaneously all fit together to create one large, complex multi-channel soundtrack.",
    role: "Creative Direction (Audio) \nProject Management (Audio) \n\nfor Idee und Klang Audio Design",
    credits:
      "Client - Dubai Future Foundation \nExhibition Design - Galerija 12 \nAV Integration - Neumann & Müller \nPhoto & Video Credit - Galerija 12",
  },

  "media-world": {
    order: 7,
    projectName: "Media World",
    tagline: "A Procedural Soundscape Controlled by Visitor Behaviour",
    skills: "Software Development",
    year: "2016",
    projectLink: "/projects/media-world",
    projectTags: "All Projects Installation Interactive Selected Projects UX",
    teaserImage: "/projects/media-world/media-world-teaser.gif",
    pictureGallery: {
      path: "/projects/media-world/media-world-gallery",
      pictures: 2,
    },
    videoID: "l4SQxmaAYMM",
    shortDescription:
      "The Verkehrshaus Luzern in Switzerland currently houses the Media World exhibition. It invites visitors to discover today's versatile media landscape by experiencing the potential of modern media technology first-hand. Co-founder of the Media World is the Red Bull Media House. Idee und Klang provided sound and music for the exhibition developing a generative multi-channel audio engine that creates the soundscape on-site in real-time.",
    longDescription:
      "Visitor behaviour is tracked throughout the museum. The audio engine receives these parameters and uses them to create and shape sound and music. Parameters include the total amount of visitors currently present in the exhibition or visitor interaction at specific points within the museum. This data is then used to control musical meta-parameters such as intensity, spectral richness, and pulse of the ambient music. \n\nAnalogous to the vast amounts of information in today's media landscape, 'Sonic Clouds,' a chaotic blend of concrete sounds such as conversation, cheers, well known sounds of various electronic devices, or engine noises, freely float through the entire museum adding a spatial element to the soundscape. The density, speed and movement patterns of the clouds are dependant on the visitors' use of the various interactive stations throughout the museum. Thus, the sound of the exhibition is always as active as its visitors. ",
    role: "Development of Generative Audio Engine (Software, Sound Design, Composition) \n\nfor Idee und Klang Audio Design",
    credits:
      "Client - Verkehrshaus Schweiz \nMedia World Partner - Red Bull AG \nLead Design, Concept & Realisation - Schmauder Rohr GmbH \nScenography and architecture - Frank Dittman GmbH \nMedia Technology - iart AG \nVideo Credit - iart AG \nPhoto Credit - Red Bull AG",
  },

  subtext: {
    order: 8,
    projectName: "SubText",
    tagline: "Generating Musical Phrases from Keystrokes",
    skills: "Prototyping",
    year: "2021",
    projectLink: "/projects/subtext",
    projectTags: "All Projects Interactive",
    teaserImage: "/projects/subtext/subtext-teaser.gif",
    // pictureGallery: {
    //   path: "/projects/subtext/subtext-gallery",
    //   pictures: 2,
    // },
    videoID: "2a7GGcxfY8U",
    shortDescription:
      "SubText is a web-based text editor that sonifies your keystrokes. It is inspired by Plan8's JazzKeys. The interface maps characters, periods, backspace, escape, enter and question marks across sample playback, reverb, distortion, and filters.",
    // longDescription: "no long text.",
    // role: "Sound Design \nMusic Production",
    tech: "React.js \nWeb Audio API",
    // recognition: "• what did you achieve with this?",
  },

  "koenigsfelden-monastery": {
    order: 9,
    projectName: "Koenigsfelden Monastery",
    tagline: "A Sonic Time Machine",
    skills: "Software Development",
    year: "2016",
    projectLink: "/projects/koenigsfelden-monastery",
    projectTags: "All Projects Installation Interactive Selected Projects",
    teaserImage: "/projects/ganz-ohr/ganz-ohr-teaser.jpg",
    pictureGallery: {
      path: "/projects/ganz-ohr/ganz-ohr-gallery",
      pictures: 4,
    },
    videoID: "TirAAw-a4_M",
    shortDescription:
      'Under the theme "All Ears", the Museum Aargau takes its visitors on a sonic journey of discovery to six historical sites. In an open air installation located in the garden of Königsfelden Monastery in Windisch, Switzerland, visitors can manipulate a pointer on a clock-like interface to move through soundscapes comprised of artistic interpretations of the daily routines of the monastery\'s former inhabitants.',
    longDescription:
      "The internal mechanics of the clock are simulated by a transducer built into the interface, providing haptic feedback when moving the pointer across full hours, signalling mechanical changes to foreshadow transitions in the ambient sound, and guiding visitors to the interface in the middle of the garden through a subtle ticking sound. The surrounding multi-channel ambient system reacts to different clock pointer positions which ambient soundscapes for each full hour, inspired by the strict daily routines of the nuns. Individual parts of the routine are announced through processed versions of a church bell from the monastery's own bell tower. The installation allows visitors to explore the site's history through their own actions. The immersive installation blends concrete sounds and musical elements illustrating the interplay of strict routines and spirituality.",
    role: "Concept Development \nComposition, Sound Design \nDevelopment of Interactive System \nOn-Site Implementation \n\nfor Idee und Klang Audio Design",
    credits:
      "Client: Museum Aargau \nTechnology: Tweaklab AG \nPhoto: Museum Aargau",
  },

  "hyundai-motorstudio-goyang": {
    order: 10,
    projectName: "Hyundai Motorstudio Goyang",
    tagline: "Sound and Music in South Korea's largest Mobility Theme Park",
    skills: "Composition • Music Production",
    year: "2017",
    projectLink: "/projects/hyundai-motorstudio-goyang",
    projectTags: "All Projects Installation",
    teaserImage: "/projects/gac/gac-teaser.gif",
    pictureGallery: { path: "/projects/gac/gac-gallery", pictures: 5 },
    videoID: "ZE7WNB6D6EE",
    shortDescription:
      "Hyundai Motorstudio Goyang is the largest automobile theme park in Korea, located about 40min northwest of Seoul. Its extensive facilities host a large exhibition on four levels in which visitors get a chance to learn about how cars are manufactured and how they function in a fun and entertaining way.",
    longDescription:
      "From the first stage of the competition for this project Idee und Klang collaborated with the team of Klangerfinder GmbH & Co KG in Stuttgart in a joint venture in order to form an interdisciplinary team of both companies. After creating the overall sound concept for the complete facilities, we composed and designed sounds for every area of the exhibition and most of the common areas (hallways, elevators etc). This holistic approach enabled us to turn the Hyundai Motorstudio Goyang into a rich auditive environment that forms a coherent entity with the Hyundai brand. \n\nWhen entering the exhibition, visitors embark on an emotional journey that takes them through every phase of car manufacturing. A lot of the knowledge is conveyed via hands-on interaction. We've given each area its own distinct auditive character by means of multichannel soundscapes, film soundtracks, interactive sound installations and a wfs sound story. The highlight of the exhibition is the Design Area featuring a beautifully designed kinetic sculpture and a spectacular 360 degree film presentation for which we have created multichannel sound design and music. ",
    role: "Composition \nMusic Production \n\nfor Idee und Klang Audio Design",
    credits:
      "Architecture : DMAA \nExhibition Design: ATELIER BRÜCKNER GmbH \nMedia Production for the Design Area: Tamschick Media+Space \nLight Design: LDE Belzner Holmes \nAV Planning: Techdata \nAV Consulting: medienprojekt p2 \nPhoto, Video: ATELIER BRÜCKNER",
  },

  "museum-of-the-future-2017": {
    order: 11,
    projectName: "World Government Summit Dubai 2017",
    tagline: "Water, Food, and Shelter in 2050",
    skills: "Immersive Sound Design • Software Development",
    year: "2017",
    projectLink: "/projects/museum-of-the-future-2017",
    projectTags: "All Projects Installation Interactive Selected Projects",
    teaserImage: "/projects/mof17/mof17-teaser.gif",
    pictureGallery: { path: "/projects/mof17/mof17-gallery", pictures: 6 },
    videoID: "WP1wikO95KI",
    shortDescription:
      "Idee und Klang created the music and sound design for 2017's Museum of the Future in Dubai. The exhibition addresses the reality of climate change and presents a future where we have not only survived the challenges of climate change in the mid-21st century, but have thrived.",
    longDescription:
      "The exhibition emphasises the inevitability of major environmental change and the importance of being proactive and able to adapt. For the centrepiece of the museum, a 360 degree film, we produced an immersive soundtrack using an Auro-11.1 setup with additional floor speakers and multiple shakers built into the floor. All in all more than 80 speakers were used in the different areas, fusing a unique audio experience with the museum's impressive visual content. \n\nWe also heavily relied on generative sound design and composition techniques. On interactive stations throughout the museum, visitors could build their own cities or give their opinions on how to best tackle the environmental challenges ahead. Sonic feedback to their input was designed to both represent the specific user action (eg. button presses) as well as its semantic / emotional content (eg. growing sustainable cities in a barren wasteland). Ambient and interaction sounds were weaved into each other, allowing the visitors to actively enrich the sonic environments by adding harmonic layers and setting musical accents.",
    role: "Composition, Sound Design \nMultichannel Mix \nDevelopment of Interactive System \nOn-Site Implementation \n\nfor Idee und Klang Audio Design",
    credits:
      "The Museum of the Future at the 2017 World Government Summit is supported by the Dubai Future Foundation and the UAE telecommunications giant du. It was led by the Foundation's team and directed by the global design firm Tellart, with the support of an international team of top partners including Marshmallow Laser Feast, SOFTlab, Spacehabs, Idee und Klang, Lust, Cropbox and Shtik. The onsite build and installation was done in collaboration with Projex, Neumann & Müller, Hypsos and PublicisLive. Foresight research and workshop facilitation was performed by Scott Smith (Changeist).",
  },

  "gesture-based-performance-system": {
    order: 12,
    projectName: "Gesture-Based Performance System",
    tagline: "Sonifying Human Gesture",
    skills: "Prototyping",
    year: "2018",
    projectLink: "/projects/gesture-based-performance-system",
    projectTags: "All Projects Interactive",
    teaserImage: "/projects/geps/geps-teaser.jpg",
    pictureGallery: { path: "/projects/geps/geps-gallery", pictures: 5 },
    videoID: "Tx407N-nIUk",
    shortDescription:
      "The “Gesture-based Performance System” (GePS) is a digital musical instrument designed to enable intuitive gestural control of electronic sound modules. Through a small sensor unit worn on the wrist, the DMI catches gestural data of hand and arm and uses it to control a sampler & synthesis engine.",
    longDescription:
      "The motivation behind the project was an apparent disconnect between performer movement and instrument sound that many electronic instruments have. A player's bodily involvement is rarely a concern of the instrument designer and therefore risks being ignored by the performer as well. This, in our opinion, can make performers of electronic music seem detached from their music and thereby harm performances. \n\nExploring one possible solution, we designed our DMI's control mappings with intuitive (for the performer) and clear (for the audience) movement-sound relationships in mind. We achieved this through the use of transparent control metaphors: in order to benefit from the listener's and performer's natural understanding of physical processes around them, we used gestures with strong physical associations, which were then mapped to sound modules specifically designed to represent these associations sonically. The sound aesthetics were oriented towards the sonic language of acousmatic music, as it lends itself particularly well to associations to physical processes. When playing the instrument, any gesture performed has a corresponding sound and every sound has a corresponding gesture. As a result, the performers bodily involvement is determined by the DMI's design and seen as necessary and justified from the audience's perspective.",
    role: "Sonic Interaction Design \nMicrocontroller Prototyping \nSoftware Synthesizer Development \n\nA collaboration with Cedric Spindler",
    recognition:
      "• Finalist 2015 Margaret Guthman Musical Instrument Competition \n• Finalist 2018 Hackaday Challenge \n• Publication: Robinson et. al. - 'Gestural control in electronic music performance: sound design based on the striking and bowing movement metaphors'",
  },

  "king-abdulaziz-center-for-world-culture": {
    order: 13,
    projectName: "King Abdulaziz Center for World Culture",
    tagline: "A Journey through Saudi Arabia's Biotopes",
    skills: "Immersive Sound Design",
    year: "2018",
    projectLink: "/projects/king-abdulaziz-center-for-world-culture",
    projectTags: "All Projects Installation",
    teaserImage: "/projects/arabian-journeys/arabian-journeys-teaser.gif",
    pictureGallery: {
      path: "/projects/arabian-journeys/arabian-journeys-gallery",
      pictures: 4,
    },
    videoID: "3CRfGf7jItw",
    shortDescription:
      "Arabian Journeys is a natural history museum at the King Abdulaziz Center for World Culture. Over 8 areas, visitors are taken on a journey through saudi arabias rich natural environments, from the pre-historic formation of the arabian peninsula and its earliest flora and fauna, through the deserts, mountains and coastal regions with their respective wildlife, to an interactive outlook into the future.",
    longDescription:
      "We created sound and music for interactive stations, video guides, and ambient sound throughout the entire exhibition. Each area's 360° projections are accompanied by multi-channel soundscapes that sonically represent the different biotopes  through a delicate mixture of natural and musical elements. Areas also include interactive stations that add local sonic elements to the soundscapes, overall creating an immersive sonic environment that brings the impressive visual landscape to life.",
    role: "Sound Design \nMultichannel Mix \nOn-Site Implementation \n\nfor Idee und Klang Audio Design",
    credits:
      "General Planning: ATELIER BRÜCKNER \nExhibition Concept, Design, Scenography: ATELIER BRÜCKNER \nLight Planning: LDE Belzner Holmes \nMedia Planning: medienprojekt p2 \nMedia Design: iart \nGraphic Design: Tarek Atrissi \nFilm Production: iart with TAMSCHICK MEDIA+SPACE \nPhoto: ATELIER BRÜCKNER / Daniel Stauch",
  },

  "art-stable-oslo": {
    order: 14,
    projectName: "Art Stable at the Royal Palace Oslo",
    tagline: "A Present for the Queen of Norway",
    skills: "Immersive Sound Design",
    year: "2017",
    projectLink: "/projects/art-stable-oslo",
    projectTags: "All Projects Installation",
    teaserImage: "/projects/maud/maud-teaser.gif",
    pictureGallery: { path: "/projects/maud/maud-gallery", pictures: 4 },
    videoID: "XPecEhdFmWs",
    shortDescription:
      "Queen Maud's Album was a two-year long exhibit that opened in July 2017 as part of the new Queen Sonja “Art Stable” at the Royal Palace in Oslo. It explored Queen Maud's visual universe during her reign from over 44 albums of photographic archives she left.",
    longDescription:
      "The exhibit is installed in the Royal Stables' Hay Tower and features photographs with Queen Maud both in front of and behind the camera. Queen Maud was a passionate equestrian who expanded and remodelled the Royal Stables from 1908-1911, which remains the current design today. The exhibit showcases six themes highlighting Queen Maud's life and work: The Coronation, The Joys of Winter, The Gala, Riding, In Merry Company, and Summer Life. \n\nEach theme is introduced by a 3D scanned rendering of one of Queen Maud's fashion ensembles, accompanied by numerous photographs exploring the corresponding theme. The costumes were scanned using a technique called Photogrammetry, which allowed the digitisations to showcase the intricate and beautiful details of the historic wardrobe. \n\nIn collaboration with Tellart and composer Henrik Skram who composed the original music score for the exhibit, Idee und Klang Audio Design created the immersive sound design for the installation. Over the course of the 26-minute experience visitors are guided through the sonic environments of the photographs, from gardens to snowcapped mountains, from cycling trips to military marches. Soundscapes and music are woven together and immerse the audience within a 3D-Audio setup. Besides the sound design we also contributed custom-made software allowing adjustment of the exhibit's volume based on the amount of people inside the installation area.",
    role: "Sound Design, Multichannel Mix \nOn-Site Implementation \nProject Management (Audio) \n\nfor Idee und Klang Audio Design",
    credits:
      "Client - Tellart \nLead Design, Concept & Realisation - Tellart \nMusic composition - Henrik Skram",
  },
};

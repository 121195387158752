import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const About = (props) => {
  const [loaded, setLoaded] = useState(0);

  return (
    <>
      <Helmet>
        <title>Portfolio | Frederic Robinson</title>
        <meta name="title" content="Portfolio | Frederic Robinson" />
      </Helmet>
      <div className="px-5 flex flex-col-reverse lg:flex-row items-center justify-between max-w-screen-lg mx-auto transition-all">
        <div className="pt-8 lg:max-w-lg lg:py-8 flex flex-col md:items-start md:text-left md:mb-0 items-center">
          <h1 className="title-font text-lg md:text-xl mb-4 lg:-mt-12 font-medium text-black">
            <br className="hidden lg:inline-block" />
            Hi! I am a Product Strategist & Interaction Designer
            combining human-centered design expertise and a diverse technical
            skill set to ideate, prototype, and evaluate new products and user
            experiences.
          </h1>
          <p className="mb-4 pt-4 leading-relaxed border-t text-gray-900 border-gray-700 ">
            In previous work at Meta Inc. and Dolby Laboratories, I was embedded
            in technology incubation teams, ideating and prototyping user
            experiences powered by next-gen technologies to inform research and
            product roadmaps. I hold a PhD in Human-Computer Interaction from
            the University of New South Wales.
          </p>
          <p className="mb-4 pt-4 leading-relaxed border-t text-gray-900 border-gray-700">
            Most recently, I co-founded{" "}
            <a
              href="https://www.sentrystrategy.com/"
              className="underline text-gray-900 hover:text-blue-700 transition-all duration-200"
              target="_blank"
            >
              Sentry Strategy
            </a>
            , where I offer technology consulting and digital transformation
            services for international clients across industries, helping them
            unlock the potential of established and emerging technologies. To
            learn more about my work and portfolio, reach out!
          </p>
          <div className="flex justify-center pt-5">
            {/* <Link to="/projects">
              <div className="text-white bg-blue-600 border-2 border-white py-2 px-6 focus:outline-none hover:bg-blue-500 transform hover:scale-105 rounded-lg text-lg transition-all duration-300">
                Check Out My Work
              </div>
            </Link> */}
            <a
              href="mailto:frederic.robinson@sentrystrategy.com"
              className="text-white bg-blue-600 border-2 border-white py-2 px-6 focus:outline-none hover:bg-blue-500 transform hover:scale-105 rounded-lg text-lg transition-all duration-300"
            >
              Get In Touch
            </a>
            {/* <a
              href="mailto:fredericanthonyrobinson@gmail.com"
              className="ml-4 inline-flex text-black bg-white border-2 box-border border-black hover:border-blue-600 py-2 px-6 focus:outline-none rounded text-lg transform hover:scale-105 transition-all duration-300"
            >
              Get In Touch
            </a> */}
          </div>
        </div>
        <img
          className={
            "w-screen mdd:w-4/6 lg:w-2/5 shadow-2xl max-h-96 lg:max-h-full lg:max-w-md object-cover object-top sm:object-mid-top rounded-lg transition-all duration-200 opacity-" +
            loaded
          }
          alt="Portrait"
          src="images/portrait.jpg"
          onLoad={() => setLoaded(100)}
        />
      </div>
    </>
  );
};
